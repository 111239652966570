import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators';
import { IRolesModule, RolesModuleActions as Actions } from '@/store/modules/roles/IRolesModule';
import { NewRole, Role, SubRole, UpdateRole, User } from '@/typings/domain';

import ApiClient from '@/api/api.client';
import store from '@/store';
import StoreModules from '@/store/StoreModules';
import UsersModule from '../users/UsersModule';

@Module({
  dynamic: true,
  name: StoreModules.Roles,
  namespaced: true,
  store,
})
class RolesModule extends VuexModule implements IRolesModule {
  roles: Role[] = [];
  page = 1;
  closed = false;
  endPaginateRoles = false;
  selectedRoles: Array<number> = []
  constructor(props: unknown) {
    super(props);
  }

  get actualRoles() {
    return this.roles.filter((el, index) => 
    {
      return this.roles.indexOf(el) === index
    })
  }
  get paginationPage() {
    return this.page;
  }
  get activeRoles(): Array<number> {
    return this.selectedRoles;
  }

  get specialisations(): Array<SubRole> {
    let specialisations: Array<SubRole> = [];
    
    this.roles.forEach(role => {
      
      specialisations = [...specialisations, ...role.specialisations];
    });

    return specialisations;
  }

  @Mutation
  deleteSpecialization({role_id, id}: {role_id: number, id: number}): void {
    for (const role of this.roles) {
      if (role.id === role_id) {
        const idx = role.specialisations.findIndex(sp => sp.id === id);
        if (idx >= 0) {
          
          role.specialisations.splice(idx, 1);
        }
      }
    }
  }

  @Mutation
  addSpecialization({ parentId, specialisations }: { parentId: number; specialisations: SubRole }) {
    for (const role of this.roles) {
      if (role.id === parentId) {
        role.specialisations.push(specialisations);
        return;
      }
    }
  }

  @Mutation
  setPaginationPage(page: number) 
  {
    this.page = page  
  }
  
  @Mutation
  setRoles(roles: Role[]) {
    this.roles = this.roles.concat(roles);
    
    if(this.roles.length)
    {
      this.roles.forEach((el, index) => 
      {
        if(el.is_admin)
        {
          this.roles[index].specialisations = el.specialisations.map((element) =>
          {
            return {
              ...element,
              is_admin: true,
            }
          })
        }
      })
    }

    this.roles = this.roles.filter((item, index) => {
      return !this.roles.slice(0, index).some((prevItem) => {
        return JSON.stringify(prevItem.name) === JSON.stringify(item.name);
      }); 
    }) as Role[];
  }

  @Mutation
  addRole(role: Role) {
    this.roles.unshift(role);
  }
  @Mutation setRolesByLoad(arr: Array<number>)
  {
    this.selectedRoles = arr
  }

  @Mutation clearRoles()
  {
    this.page = 1;
    this.roles = []
  }

  @Mutation 
  increasePage(page: number)
  {
    this.page = page
  }

  @Mutation setClosedContext(closed: boolean)
  {
    this.closed = closed
  }

  @Mutation updateEditedRole({id, name}: {id: number, name: string})
  {
    const index = this.roles.findIndex((el) => el.id === id)
    if(index >= 0)
    {
      this.roles[index].name = name;
    }
  }

  @Mutation deleteRoleById(id: number) {
    const idx = this.roles.findIndex(role => role.id === id);
    
    if (idx >= 0) {
      this.roles.splice(idx, 1);
      return;
    }
  }
  @Mutation stopPagination(status: boolean) {
    this.endPaginateRoles = status
  }

  /**
   * @Action fetchRoles
   */
  @Action
  async [Actions.fetchRoles](params?: any) {
    // if(this.endPaginateRoles) return;
    await ApiClient.roles.getAllRoles(params).then(rolesFromServer => {
      if(!rolesFromServer.list.length)
      {
        this.stopPagination(true)
      }
      else
      { 
        this.stopPagination(false);
        this.increasePage(this.paginationPage + 1);
        this.setRoles(rolesFromServer.list);
      }
    })
  }

  /**
   * @Action fetchSingleRole
   */

  @Action
  async fetchSingleRole(id: number) {
    // if(this.endPaginateRoles) return;
    await ApiClient.roles.getSingleRole(id).then(role => {
      return role;
    })
  }

  /**
   * @Action createRole
   */
  @Action
  async [Actions.createRole](newRole: NewRole) {
    const result = await ApiClient.roles.createRole(newRole.name);
    // eslint-disable-next-line
    // @ts-ignore
    // console.log(result.data);
    
    this.addRole(result.data);
  }
  /**
   * @Action editRole
   */
  @Action
  async [Actions.editRole](newRole: UpdateRole) {
    const response = await ApiClient.roles.editRole({name: newRole.name, roleId: newRole.id, is_admin: newRole.is_admin}) as any;
    this.updateEditedRole({name: response.data.name, id: response.data.id});
  }
  /**
   * @Action deleteRole
   */
  @Action
  async [Actions.deleteRole](id: number) {
    await ApiClient.roles.deleteRole(id);
    this.deleteRoleById(id);
  }

  /**
   * @Action createSpecialization
   */
  @Action
  async [Actions.createSpecialization]({ parentId, name }: { parentId: number; name: string }) {
    const specialisations = await ApiClient.specialisations.createSpecialisation(name, parentId);
    this.addSpecialization({ parentId, specialisations });
    return specialisations;
  }

  /**
   * @Action deleteSubRole
   */
  @Action
  async [Actions.deleteSubRole](id: number) {
    this.roles.forEach(role => {
      if (role.specialisations) {
        const idx = role.specialisations.findIndex(spec => spec.id === id);
        if (idx >= 0) {
          const id = role.specialisations[idx].id;
          ApiClient.roles.deleteSubRole(id).then(() => this.deleteSpecialization({role_id: role.id, id: id}));

          return;
        }
      }
    });
  }
  
}

export default getModule(RolesModule);
