import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import StoreModules from '@/store/StoreModules';
import store from '@/store';
import ApiClient from '@/api/api.client';
import { IKnowledgeModule, KnowledgeModuleActions as Actions } from '@/store/modules/knowledge/IKnowledgeModule';
import { KnowledgeItem } from '@/typings/domain';

@Module({
    dynamic: true,
    name: StoreModules.Knowledge,
    namespaced: true,
    store,
})
class KnowledgeModule extends VuexModule implements IKnowledgeModule {
    data: KnowledgeItem[] = [];
    filter = false;
    loading = false;

    get isKnowledgeLoading()
    {
        return this.loading;
    }

    @Mutation
    public setData(payload: {result: { data: Array<KnowledgeItem> }, page?:number}) {
        if(payload.page === 1 && this.data.length)
        {
            const dataLength = this.data.length

            this.data = this.data.concat(payload.result.data)

            this.data = this.data.slice(dataLength, this.data.length)
            .sort((a, b) => {
                if (a.pinned && b.pinned) {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                } else return 0;
            })
            .sort((a, b) => (a.pinned === b.pinned ? 0 : a.pinned ? -1 : 1));
        }
        else
        {
            this.data = this.data.concat(payload.result.data)
            .sort((a, b) => {
                if (a.pinned && b.pinned) {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                } else return 0;
            })
            .sort((a, b) => (a.pinned === b.pinned ? 0 : a.pinned ? -1 : 1));
        }
    }

    @Mutation
    public setFilterStatus() {
        this.filter = true;
    }

    @Mutation
    public offFilterStatus() {
        this.filter = false;
    }

    @Mutation
    public clearKnoledges() {
        this.data = [];
    }

    @Mutation
    public updateSort() {
        this.data = this.data
            .sort((a, b) => {
                if (a.pinned && b.pinned) {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                } else return 0;
            })
            .sort((a, b) => (a.pinned === b.pinned ? 0 : a.pinned ? -1 : 1));
    }

    @Mutation
    public updateCategoryName({id, name}: {id: number; name: string})
    {
        const targetIdx = this.data.findIndex(el => el.id === id);
        this.data[targetIdx].name = name;
    }

    @Mutation
    public updateCategoryPinned({ id, state }: { id: number; state: boolean }) {
        const targetIdx = this.data.findIndex(el => el.id === id);
        this.data[targetIdx].pinned = state;
    }
    @Mutation
    public deleteLocalCategory(targetId: number) {
        const targetIdx = this.data.findIndex(el => el.id === targetId);
        this.data.splice(targetIdx, 1);
    }
    @Mutation
    public deleteLocalSubcategory(targetId: number) {
        let targetIdx = -1;
        const categoryIdx = this.data.findIndex(el => {
            return (
                el.subcategories.findIndex((sub, subIdx) => {
                    if (sub.id === targetId) {
                        targetIdx = subIdx;
                        return true;
                    }
                }) !== -1
            );
        });

        this.data[categoryIdx].subcategories.splice(targetIdx, 1);
    }
    @Mutation
    public deleteLocalKnowledges(targetId: number) {
        let targetIdx = -1;

        const categoryIdx = this.data.findIndex(el => {
            return (
                el.knowledges && el.knowledges.findIndex((sub, subIdx) => {
                    if (sub.id === targetId) {
                        targetIdx = subIdx;
                        return true;
                    }
                }) !== -1
            );
        });

        this.data[categoryIdx].knowledges.splice(targetIdx, 1);
    }

    @Mutation toggleLoading()
    {
        this.loading = !this.loading
    }

    @Mutation
    public deleteLocalSubcategoryKnowledges(targetId: number) {
        let targetIdx = -1;
        let subcategoryIdx = -1;

        const categoryIdx = this.data.findIndex(el => {
            if (el.subcategories)
            {
                subcategoryIdx = el.subcategories.findIndex((sub, subIdx) => {
                    return (
                        sub.items.findIndex((item, itemIdx) => {
                            if (item.id === targetId) {
                                targetIdx = itemIdx;
                                subcategoryIdx = subIdx;
                                return true;
                            }
                        }) !== -1
                    );
                });
                return subcategoryIdx !== -1;
            }
        });

        this.data[categoryIdx].subcategories[subcategoryIdx].items.splice(targetIdx, 1);
    }

    @Action({rawError: true})
    async [Actions.fetchKnowledge](filteredData?: 
        { 
            specialisations?: { id?: number }[]; 
            tags?: { id?: number }; 
            page?: number;
            projectId?: number;
            clientId?: number,
        })
    {   
        const filter = {
            filter: 
            {
                specialisations: filteredData.specialisations ? filteredData.specialisations[0]?.id : null,
                tags: filteredData.tags?.id ? filteredData.tags?.id : null,
            },
            'filter[projects]': filteredData.projectId,
            'conditions[client]': filteredData.clientId,
            page: filteredData.page ? filteredData.page : null,
        }
        if(!filteredData.projectId)
        {   
            delete filter['filter[projects]']
        }
        else if(!filteredData.clientId)
        {
            delete filter['conditions[client]']
        }
        this.toggleLoading()
        const result = await ApiClient.knowledge.getKnowles(
            filteredData
                ? filter
                : {},
        ).finally(() => 
        {
            this.toggleLoading();
        });
        this.setData({result: result, page: filteredData.page});
    }
    @Action
    async [Actions.deleteKnowledge](id: number) {
        await ApiClient.knowledge.deleteKnowledge(id);
        const result = await ApiClient.knowledge.getKnowles();
        this.setData({result: result});
    }

    @Action
    async fetchKnowledges(filter: string) {
        return await ApiClient.knowledge.getKnowledges(filter)
    }
}

export default getModule(KnowledgeModule);
